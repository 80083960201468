const state = {
  socket: ''
}

const mutations = {
  SET_SOCKET: (state, socket) => {
    state.socket = socket
  },
}

const actions = {
  
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
