<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
const beforeUnload = function (e) {
  e = e || window.event
  if (e || window.event) e.returnValue = 1;
  return 1;
}
export default {
  name: 'App',
  data() {
    return {
      client: null,
      messages: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('beforeunload', beforeUnload)
    })
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', beforeUnload)
    if (this.client) {
      this.client.end();
    }
  },
  methods: {
  },
}
</script>
